import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';

import SignatureCanvas from 'react-signature-canvas';
import { ToastContext } from '../../contexts/ToastContext';

import './SignatureModal.css';

export const SignatureModal = ({ isOpen, close, username, userEmail, userPhoneNumber, userMember1Name }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [member1Name, setMember1Name] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    const { notify } = useContext(ToastContext);

    const canvasRef = useRef();

    useEffect(() => {
        username && setName(username);
        userEmail && setEmail(userEmail);
        userMember1Name && setMember1Name(userMember1Name);
        userPhoneNumber && setPhoneNumber(userPhoneNumber);
    }, [username, userEmail, userMember1Name, userPhoneNumber]);

    const onNameChange = (ev) => setName(ev.target.value);
    const onEmailChange = (ev) => setEmail(ev.target.value);
    const onMember1NameChange = (ev) => setMember1Name(ev.target.value);
    const onPhoneNumberChange = (ev) => setPhoneNumber(ev.target.value);
    const onStreetChange = (ev) => setStreet(ev.target.value);
    const onCityChange = (ev) => setCity(ev.target.value);
    const onStateChange = (ev) => setState(ev.target.value);
    const onZipCodeChange = (ev) => setZipCode(ev.target.value);

    const onSave = useCallback(() => {
        if (canvasRef.current.isEmpty()) {
            notify('Please sign first.');
            return;
        };

        if ([name, email, phoneNumber, member1Name, street, city, state, zipCode].some((el) => !el || !el.trim())) {
            notify('Please complete all required fields.');
            return;
        };

        if (name.length > 256) {
            notify('Name is too long.');
            return;
        };

        if (street.length > 42) {
            notify('Street is too long.');
            return;
        } else if (street.length < 2) {
            notify('Street is too short.');
            return;
        };

        if (!(/^[a-zA-Z0-9\s,.'-]+$/.test(street))) {
            notify('Street is invalid.');
            return;
        };

        if (city.length < 2) {
            notify('City is too short.');
            return;
        };

        if (!(/^[a-zA-Z0-9\s,.'-]+$/.test(city))) {
            notify('City is invalid.');
            return;
        };

        if (state.length < 2) {
            notify('State is too short.');
            return;
        };

        if (!(/^[a-zA-Z0-9\s,.'-]+$/.test(state))) {
            notify('State is invalid.');
            return;
        };

        if (zipCode.length < 2) {
            notify('Zip Code is too short.'); 
            return;
        };

        if (!(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(zipCode))) {
            notify('Zip Code is invalid'); 
            return;
        };

        const signatureInBase64 = canvasRef.current.getCanvas().toDataURL();
        
        close(signatureInBase64, {
            name,
            email,
            phoneNumber,
            member1Name,
            street,
            city,
            state,
            zipCode,
        });
    }, [close, name, email, phoneNumber, member1Name, street, city, state, zipCode]);

    const onClear = () => canvasRef.current.clear();

    return (
        <Modal
            isOpen={isOpen}
            toggle={close}
            className="signature-modal"
            contentClassName="signature-modal-content"
        >
            <ModalHeader toggle={close}>Sign Membership Agreement</ModalHeader>
            <ModalBody>
                <p className='description'>Please complete all required fields and sign in the gray signature box</p>
                <Form>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Name"
                                    value={name}
                                    onChange={onNameChange}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="memberName"
                                    id="memberName"
                                    placeholder="Wallet Name"
                                    value={member1Name}
                                    onChange={onMember1NameChange}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={onEmailChange}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    placeholder="Phone Number"
                                    value={phoneNumber}
                                    onChange={onPhoneNumberChange}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="street"
                                    id="street"
                                    placeholder="Street"
                                    value={street}
                                    onChange={onStreetChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="City"
                                    value={city}
                                    onChange={onCityChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="state"
                                    id="state"
                                    placeholder="State"
                                    value={state}
                                    onChange={onStateChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    placeholder="Zip Code"
                                    value={zipCode}
                                    onChange={onZipCodeChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '12px',
                        }}
                    >
                        <SignatureCanvas
                            ref={canvasRef}
                            penColor="black"
                            clearOnResize={false}
                            canvasProps={{
                                className: 'sign-canvas',
                                onMouseDown: () => { if ('activeElement' in document) document.activeElement.blur(); },
                                onTouchStart: () => { if ('activeElement' in document) document.activeElement.blur(); },
                            }}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSave}>
                    SAVE
                </Button>
                <Button color="danger" onClick={onClear}>
                    CLEAR
                </Button>
            </ModalFooter>
        </Modal>
    );
};

SignatureModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    username: PropTypes.string,
    userEmail: PropTypes.string,
    userPhoneNumber: PropTypes.string,
};
