import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import HashLoader from 'react-spinners/HashLoader';
import constants from '../utils/constants';
import { generateQueryString } from '../utils/utils';
import { ESignService } from '../services/sign.service';
import { ToastContext } from '../contexts/ToastContext';
import { SignatureModal } from '../components';
import Met1LogoImg from '../assets/img/meta1-logo.png';

export const SignDocument = () => {
    const [userProfileLoadStatus, setUserProfileLoadStatus] = useState(constants.LOADING_STATUS.NONE);

    const [data, setData] = useState({});
    const [pagesCount, setPagesCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [isLastPageVisited, setIsLastPageVisited] = useState(false);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { notify } = useContext(ToastContext);

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const { userToken, userEmail, isMobile } = data;

        const fetchData = async () => {
            if (userEmail && userToken) {
                setUserProfileLoadStatus(constants.LOADING_STATUS.LOADING);

                try {
                    const [profileResponse, err] = await ESignService.getProfile(userEmail);
                    if (profileResponse.data?.status?.isSign) {
                        const queryString = generateQueryString({
                            token: userToken,
                            isMobile,
                        });

                        history.push(`/payment?${queryString}`);
                    }
                } catch (error) {
                    notify(error.message);
                } finally {
                    setUserProfileLoadStatus(constants.LOADING_STATUS.DONE);
                }
            }
        };

        fetchData();
    }, [data]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const isMobileParam = JSON.parse(params.get('isMobile'));
        const token = params.get('token');

        ESignService.getPole(token)
            .then(poleResponse => {
                if (poleResponse.data) {
                    const { email, firstName, lastName, phoneNumber, walletName, redirectUrl } = poleResponse.data;

                    setData({
                        userEmail: email,
                        userToken: token,
                        username: [firstName, lastName].join(' '),
                        userMember1Name: walletName,
                        userPhoneNumber: phoneNumber,
                        isMobile: isMobileParam,
                        signRedirectUrl: redirectUrl
                    })
                } else {
                    notify('Token is invalid. Please check your token manually has been changed.');
                }
            })
            .catch(() => {
                notify('Something went wrong. Can you try again?');
            });
    }, [location]);

    useEffect(() => {
        if (pageIndex + 1 === pagesCount) {
            setIsLastPageVisited(true);
        }
    }, [pageIndex, pagesCount]);

    const onPageChange = ({ currentPage }) => setPageIndex(currentPage);
    const onDocumentLoad = ({ doc }) => setPagesCount(doc._pdfInfo.numPages);
    const onSignatureModalOpen = () => setIsSignatureModalOpen(true);

    const onSignatureDraw = async (signature, address) => {
        const { userToken, userEmail, signRedirectUrl, isMobile } = data;

        setIsSignatureModalOpen(false);

        if (!signature || !address) return;
        setIsLoading(true);

        try {
            const resp = await ESignService.generateTokenForSign(userEmail);

            const [, signDocumentError] = await ESignService.signDocument({
                token: resp.headers.authorization,
                payload: { image: signature, ...address },
            });

            if (signDocumentError) {
                notify(signDocumentError.message);
                window.location.href = `${signRedirectUrl}?signature=failure`;
            } else {
                notify('Membership agreement signed successfully');
                const queryString = generateQueryString({
                    token: userToken,
                    isMobile,
                });

                history.push(`/payment?${queryString}`);
            }
        } catch (error) {
            notify(error.message);
        }

        setIsLoading(false);
    };

    return (
        <>
            {userProfileLoadStatus === constants.LOADING_STATUS.DONE ? (
                <>
                    <Container>
                        <Row>
                            <Col sm="12" className="d-flex py-2">
                                <img
                                    alt="META 1 Logo"
                                    src={Met1LogoImg}
                                    className="mx-auto"
                                    style={{ width: '100%', maxWidth: 150 }}
                                />
                            </Col>
                            <Col sm={{ size: 6, offset: 3 }}>
                                <p style={{ textAlign: 'center', color: 'white' }}>
                                    Please review and sign the META Private Membership Agreement,
                                    this is required to use our platform or any of META’s services.
                                </p>
                            </Col>
                            <Col sm="12">
                                <div style={{
                                    height: 'calc(100vh - 220px)',
                                    borderRadius: '5px',
                                    backgroundColor: '#FFF',
                                    position: 'relative',
                                }}
                                >
                                    <Viewer
                                        fileUrl={`pdf-demo.pdf`}
                                        plugins={[defaultLayoutPluginInstance]}
                                        initialPage={pageIndex}
                                        onPageChange={onPageChange}
                                        onDocumentLoad={onDocumentLoad}
                                    />
                                </div>
                            </Col>
                            <Col
                                sm="12"
                                className="d-flex py-2"
                            >
                                <Button
                                    color="warning"
                                    className="mx-auto"
                                    disabled={!isLastPageVisited || isLoading}
                                    onClick={onSignatureModalOpen}
                                    style={{
                                        width: '100px',
                                        fontWeight: '600',
                                        color: 'white'
                                    }}
                                >
                                    {isLoading ? 'Processing' : 'Sign'}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                    <SignatureModal
                        isOpen={isSignatureModalOpen}
                        close={onSignatureDraw}
                        username={data?.username}
                        userEmail={data?.userEmail}
                        userPhoneNumber={data?.userPhoneNumber}
                        userMember1Name={data?.userMember1Name}
                    />
                </>
            ) : (
                <Container
                    style={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <HashLoader color="#AFB42B" size={150} />
                </Container>
            )}
        </>
    );
};